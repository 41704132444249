<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="card card-default animated-fast fadeInLeft">
                <div class="card-body" :class="loading ? 'whirl' : ''">
                    <form-wizard @on-complete="sendMessage">
                        <template slot="step" slot-scope="props">
                            <!-- By using a custom header markup we can pass html in title attribute -->
                            <wizard-step
                                    :tab="props.tab"
                                    @click.native="props.navigateToTab(props.index)"
                                    @keyup.enter.native="props.navigateToTab(props.index)"
                                    :transition="props.transition"
                                    :index="props.index">
                                <span slot="title" :class="{'text-danger':props.tab.validationError}" v-html="props.tab.title"></span>
                            </wizard-step>
                        </template>
                        <!-- Headers -->
                        <tab-content :title="$t('support.view.contact.form.info')">

                            <h4 class="mt-3">{{$t('support.view.contact.type')}}</h4>

                            <div class="list-group mt-4">
                                <a v-for="category in categories" @click.prevent="selectCategory(category)" :class="{active: selectedCategory.slug === category.slug}" href=""
                                   class="list-group-item list-group-item-action">
                                    <h4 class="mb-1">{{ category.name }}</h4>
                                    <p class="mb-1">{{ category.description }}</p>
                                </a>
                            </div>
                        </tab-content>
                        <tab-content :title="$t('support.view.contact.form.info_message')">

                            <h4 class="mt-3">Ticket-Thema: {{ selectedCategory.name }}</h4>
                            <p class="mt-3">{{ selectedCategory.description }}</p>

                            {{$t('support.view.contact.message.info')}}

                            <hr/>

                            <b-form-group class="p-0">
                                <label>{{$t('support.view.contact.message.subject.label')}}</label>
                                <b-input v-model="formFields.title" required maxlength="100" :placeholder="$t('support.view.contact.message.subject.label')"></b-input>
                            </b-form-group>
                            <b-form-group>
                                <label>{{$t('support.view.contact.message.message.label')}}</label>
                                <b-textarea style="height: 300px" required v-model="formFields.message"
                                            :placeholder="$t('support.view.contact.message.message.label')"></b-textarea>
                            </b-form-group>

                        </tab-content>

                        <!-- Directions -->
                        <b-btn variant="secondary" slot="prev">{{$t('support.view.contact.directions.back')}}</b-btn>
                        <b-btn variant="secondary" slot="next">{{$t('support.view.contact.directions.next')}}</b-btn>
                        <b-btn variant="info" slot="finish">{{$t('support.view.contact.directions.finish')}}</b-btn>
                    </form-wizard>
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<style lang="scss">
    .markdown-body {

        &:before, &:after {
            display: none;
        }

        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        color: inherit;

        a {
            color: inherit;
        }
    }
</style>
<script>
    import {FormWizard, TabContent, WizardStep} from 'vue-form-wizard'

    export default {
        data() {
            return {
                categories: [],
                loading: true,
                waitingForKeys: false,
                selectedCategory: {
                  name: '',
                  description: '',
                  id: 0
                },
                answers: [],
                confirmNoAnswer: false
            }
        },
        components: {
            FormWizard,
            TabContent,
            WizardStep
        },
        created() {
            this.formFields.language = this.$i18n.locale;
        },
      beforeMount() {
        this.formFields.message = this.$route.params.question;

        this.$api.get('support/tickets/categories').then(response => {
          this.categories = response.data;
          this.selectCategory(this.categories[0]);
          this.loading = false;
        });
      },
        methods: {
            sendMessage() {

                if (!this.formFields.title || !this.formFields.message) {
                    this.$swal.fire('Form invalid', 'Please complete the form');
                    return false;
                }

                this.loading = true;
                this.$api.post('support/tickets', {
                    title: this.formFields.title,
                    description: this.formFields.message,
                    category: this.selectedCategory.slug,
                }).then((response) => {
                    this.loading = false;
                    this.$swal.fire('Message sent, we also sent you an copy to your email', '', 'success');
                    this.$router.push({name: 'Support:Ticket', params: {label: response.data.label}});
                }).catch(() => {
                    this.loading = false;
                    this.$swal.fire('There was an error while submitting your form', '', 'error');
                });
            },
            selectCategory(category) {
                this.selectedCategory = category;
            }
        }
    }
</script>

<style src="../../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css"></style>
